import UsesCaseTitle from "../../../../src/components/use-cases/use-cases-detail/title";
import LineNavBates from "../../../../src/components/use-cases/use-cases-detail/sections/line-nav/line-nav-bates";
import BlockQuote from "../../../../src/components/use-cases/use-cases-detail/blockquote";
import Section from "../../../../src/components/use-cases/use-cases-detail/sections";
import CardUseCase from "../../../../src/components/use-cases/use-cases-detail/sections/card";
import Seo from "../../../../src/components/seo";
import * as React from 'react';
export default {
  UsesCaseTitle,
  LineNavBates,
  BlockQuote,
  Section,
  CardUseCase,
  Seo,
  React
};