import React from "react"
import "./index.scss"
import { BsArrowRight } from "react-icons/bs"
import { Link } from "gatsby"
const Card = ({ title, para, to = "" }) => {
  return (
    <div id="Card" className=" py-3 px-3  Card  ">
      <Link className="link" to={to}>
        <div className="container">
          <h5 className="title">
            {title}
            <BsArrowRight size={20} />
          </h5>
          <p className="para">{para}</p>
        </div>
      </Link>
    </div>
  )
}

export default Card
