import imx_as from "../../../../src/docs/get-started/assets/imxapps_as.png";
import imx_rest from "../../../../src/docs/get-started/assets/imxapps_rest.png";
import generate from "../../../../src/docs/get-started/assets/generate-host.png";
import designer from "../../../../src/docs/get-started/assets/designer.png";
import apple from "../../../../src/docs/get-started/assets/apple.png";
import code from "../../../../src/docs/get-started/assets/code.png";
import import_publish_gif from "../../../../src/assets/images/docs/import_publish.gif";
import * as React from 'react';
export default {
  imx_as,
  imx_rest,
  generate,
  designer,
  apple,
  code,
  import_publish_gif,
  React
};