import conduct_inspection1 from "../../../../src/blog/new-assets/inspection1/conduct.jpg";
import manage_inspection1 from "../../../../src/blog/new-assets/inspection1/manage.jpg";
import process_inspection1 from "../../../../src/blog/new-assets/inspection1/process_.png";
import photo_inspection1 from "../../../../src/blog/new-assets/inspection1/photo.jpg";
import inspection1_maxapps from "../../../../src/blog/new-assets/inspection1/inspection1_maxapps.png";
import * as React from 'react';
export default {
  conduct_inspection1,
  manage_inspection1,
  process_inspection1,
  photo_inspection1,
  inspection1_maxapps,
  React
};