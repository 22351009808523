import React from "react"
import ImageDocs from "../layout/docs-layout/image"
import "./index.scss"

function ImageDetails({ title = "", imgSrc = "", children }) {
  return (
    <div id="ImageDetails" className="image-details row">
      <div className="details-section col-md-6">
        <p className="details-title">{title}</p>
        <p className="details-text">{children}</p>
      </div>
      <div className="image-section col-md-6">
        {/* <img src={imgSrc} /> */}
        <ImageDocs src={imgSrc} />
      </div>
    </div>
  )
}

export default ImageDetails
