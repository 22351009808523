const getSidebarEntries = (pathsIn = []) => {
  pathsIn.sort((a, b) => a.frontmatter.order - b.frontmatter.order)

  let paths = pathsIn.map(curr => curr.slug)
  let result = []
  let level = { result }
  paths.forEach(path => {
    const pathParts = path.split("/")
    const pathPartsLength = pathParts.length
    pathParts.reduce((r, text, i, a) => {
      if (!r[text]) {
        r[text] = { result: [] }
        r.result.push({
          text:
            pathPartsLength - 1 === i
              ? pathsIn.find(elm => elm.slug === path).frontmatter?.title
              : text,
          link: "/docs/" + a.join("/"),
          // link: pathPartsLength - 1 === i ? "/docs/" + a.join("/") : "#",
          depth: i,
          isActive: false,
          subItems: r[text].result,
        })
      }

      return r[text]
    }, level)
  })

  return containDocumentationRootFiles(result)
}

const containDocumentationRootFiles = (structeredNodes = []) => {
  const docsSubItems = structeredNodes.filter(
    item => item.subItems.length === 0
  )

  if (docsSubItems.length > 0) {
    let docsRoot = {
      text: "documentation",
      link: "/docs/",
      depth: 0,
      isActive: false,
      subItems: docsSubItems,
    }

    docsRoot.subItems.forEach(item => {
      item.depth = 1
    })
    structeredNodes = structeredNodes.filter(
      el => !docsRoot.subItems.includes(el)
    )
    structeredNodes.unshift(docsRoot)
  }

  return structeredNodes
}

const setActivePage = (arr = [], slug = "") => {
  arr.forEach((val, i) => {
    if (val.subItems.length === 0) {
      if (val.link === `/docs/${slug}`) {
        arr[i].isActive = true
      }
    } else {
      setActivePage(val.subItems, slug)
    }
  })

  return arr
}

const getSubItemsCount = (lst_subItems = []) => {
  let subItemsCount = lst_subItems.reduce((sum, item) => {
    return item.subItems?.length
      ? sum + getSubItemsCount(item.subItems)
      : item.text.toLowerCase() === "overview"
      ? sum
      : sum + 1
  }, 0)

  return subItemsCount
}

export { getSidebarEntries, setActivePage, getSubItemsCount }
