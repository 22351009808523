import actionsfabswipetoolbar from "../../../../src/assets/animations/actions-fab-swipe-toolbar.gif";
import _swipe from "../../../../src/assets/images/docs/how-to/features/actions/swipe.jpg";
import _fab from "../../../../src/assets/images/docs/how-to/features/actions/fab.jpg";
import _toolbar from "../../../../src/assets/images/docs/how-to/features/actions/toolbar.jpg";
import * as React from 'react';
export default {
  actionsfabswipetoolbar,
  _swipe,
  _fab,
  _toolbar,
  React
};