import React from "react"
import DocumentationItem from "./documentation-item"
import "./index.scss"
const DocumentationCards = () => {
  let result = []
  const DocumentationCards = [
    {
      title: "Ready to dive in?",
      subTitle: "Set up your environment with MAXapps containers.",
      titleButton: "Get Started",
      goTo: "/docs/get-started/",
    },
    {
      title: "New to MAXApps?",
      subTitle: "Understand MAXapps' architecture by reading this document.",
      titleButton: "MAXapps Technical Overview",
      goTo: "/docs/get-started/maxapps-architecture/",
      cardType: "secondary",
    },
  ]
  result = DocumentationCards
  return (
    <div id="DocumentationCards" className="DocumentationCards">
      <div className="row justify-content-between">
        {result.map((item, index) => {
          return (
            <div className=" col-12 col-lg-6 content" key={index}>
              <DocumentationItem data={item} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DocumentationCards
