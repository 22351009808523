// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-console-js": () => import("./../../../src/pages/console.js" /* webpackChunkName: "component---src-pages-console-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-deleteinfo-js": () => import("./../../../src/pages/deleteinfo.js" /* webpackChunkName: "component---src-pages-deleteinfo-js" */),
  "component---src-pages-do-it-yourself-js": () => import("./../../../src/pages/do-it-yourself.js" /* webpackChunkName: "component---src-pages-do-it-yourself-js" */),
  "component---src-pages-entreprise-js": () => import("./../../../src/pages/entreprise.js" /* webpackChunkName: "component---src-pages-entreprise-js" */),
  "component---src-pages-eula-js": () => import("./../../../src/pages/eula.js" /* webpackChunkName: "component---src-pages-eula-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-how-it-works-build-your-ibm-maximo-js": () => import("./../../../src/pages/how-it-works/build-your-ibm-maximo.js" /* webpackChunkName: "component---src-pages-how-it-works-build-your-ibm-maximo-js" */),
  "component---src-pages-how-it-works-combine-process-and-data-js": () => import("./../../../src/pages/how-it-works/combine-process-and-data.js" /* webpackChunkName: "component---src-pages-how-it-works-combine-process-and-data-js" */),
  "component---src-pages-how-it-works-index-js": () => import("./../../../src/pages/how-it-works/index.js" /* webpackChunkName: "component---src-pages-how-it-works-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maximo-mobile-js": () => import("./../../../src/pages/maximo-mobile.js" /* webpackChunkName: "component---src-pages-maximo-mobile-js" */),
  "component---src-pages-maximo-world-js": () => import("./../../../src/pages/maximo-world.js" /* webpackChunkName: "component---src-pages-maximo-world-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-pages-use-cases-index-js": () => import("./../../../src/pages/use-cases/index.js" /* webpackChunkName: "component---src-pages-use-cases-index-js" */),
  "component---src-pages-why-choosing-ibm-maximo-solution-js": () => import("./../../../src/pages/why-choosing-ibm-maximo-solution.js" /* webpackChunkName: "component---src-pages-why-choosing-ibm-maximo-solution-js" */),
  "component---src-pages-widgets-js": () => import("./../../../src/pages/widgets.js" /* webpackChunkName: "component---src-pages-widgets-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-docs-pages-js": () => import("./../../../src/templates/docs-pages.js" /* webpackChunkName: "component---src-templates-docs-pages-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-usecase-page-js": () => import("./../../../src/templates/usecase-page.js" /* webpackChunkName: "component---src-templates-usecase-page-js" */),
  "component---src-templates-widget-page-js": () => import("./../../../src/templates/widget-page.js" /* webpackChunkName: "component---src-templates-widget-page-js" */)
}

