import React from "react"
import "./index.scss"
const TitleDocumentation = ({ _result }) => {
  let result = {}
  try {
    const TitleDoc = {
      titleCol: "Reference Guides",
      subTitle1:
        "Nitty-gritty technical description of how MAXapps works. Most useful when you need detailed information about MAXapps' configuration and components.",
    }
    result = TitleDoc
  } catch {
    result = _result
  }
  return (
    <div
      id="TitleReference"
      className="TitleReference py-2 "
      data-testid="TitleDocumentation"
    >
      <h1 className="title">
        <span className="titleColored">{result.titleCol}</span>
      </h1>
      <p className="para py-2">{result.subTitle1}</p>
    </div>
  )
}

export default TitleDocumentation
