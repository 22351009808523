import new_record from "../../../../src/assets/images/new-record.png";
import adSearchOrderBy from "../../../../src/assets/images/adSearchOrderBy.png";
import search from "../../../../src/assets/animations/search.gif";
import advancedSearch from "../../../../src/assets/animations/advancedSearch.gif";
import advanced_button from "../../../../src/assets/images/docs/how-to/features/search-on-list/advanced_button.jpg";
import * as React from 'react';
export default {
  new_record,
  adSearchOrderBy,
  search,
  advancedSearch,
  advanced_button,
  React
};