import React from "react"

import "./index.scss"

const Section = ({ title, children, img, imgtitle = "" }) => {
  return (
    <div id="Sections" className="UseCaseImgContainer">
      {!img && (
        <div
          id={title.split(" ").join("").toLowerCase()}
          className="SectionsContainer pt-3 py-lg-5 "
        >
          <h2 className="title ">{title}</h2>
          <p className="subTitle py-3 ">{children} </p>
        </div>
      )}
      {img && (
        <div
          id={title.split(" ").join("").toLowerCase()}
          className="SectionsContainer py-3 py-lg-5 row align-items-center"
        >
          <div className="col-md-6">
            <h2 className="title ">{title}</h2>
            <p className="subTitle py-3 ">{children} </p>
          </div>
          <div className="col-md-6 pt-3">
            <img
              alt={imgtitle}
              title={imgtitle}
              src={img}
              className="img-fluid"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Section
