import { Link } from "gatsby"
import React from "react"
import { BsArrowRight } from "react-icons/bs"
import "./index.scss"
const UsesCaseTitle = ({ topTitle, coloredTitle, image = "" }) => {
  return (
    <div
      id="UsesCaseTitle"
      className="UsesCaseTitle"
      data-testid="UsesCaseTitle"
    >
      <p className="topTitle p-4 text-center">
        <Link to="/use-cases/" className="topTitleLink  " data-testid="link">
          Use Cases
        </Link>
        <BsArrowRight size={16} />
        {topTitle}
      </p>
      <div className="align-items-center text-center justify-content-center">
        <img
          alt={`Maxapps: use case ${topTitle}`}
          title={`Maxapps: use case ${topTitle}`}
          src={image}
          className="image"
        />
        <div className="titleContainer pt-2">
          <h1 dangerouslySetInnerHTML={{ __html: coloredTitle }}></h1>
        </div>
      </div>
      <div className="py-5 text-center">
        <Link to="/get-started/" className="button " data-testid="button">
          Get Started With MAXapps
        </Link>
      </div>
    </div>
  )
}

export default UsesCaseTitle
