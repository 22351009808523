import React from "react"
import TitleDocumentation from "./title"
import ArticleSection from "./article-section"

const HowTo = ({ slug }) => {
  return (
    <div className="mx-2">
      <TitleDocumentation />
      <div className="pt-3">
        <ArticleSection slug={slug} />
      </div>
    </div>
  )
}

export default HowTo
