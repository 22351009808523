import img0_inspection_form from "../../../../src/blog/new-assets/inspection2/img0_inspection_form.png";
import img1_create_form from "../../../../src/blog/new-assets/inspection2/img1_create_form.png";
import img2_name_type_instru from "../../../../src/blog/new-assets/inspection2/img2_name_type_instru.png";
import img3_question_settings from "../../../../src/blog/new-assets/inspection2/img3_question_settings.png";
import img4_question_groups from "../../../../src/blog/new-assets/inspection2/img4_question_groups.png";
import img5_follow_up_action from "../../../../src/blog/new-assets/inspection2/img5_follow_up_action.png";
import img6_conditional_question from "../../../../src/blog/new-assets/inspection2/img6_conditional_question.png";
import mockup2_save from "../../../../src/blog/new-assets/inspection2/mockup2_save.jpg";
import mockup3_fields from "../../../../src/blog/new-assets/inspection2/mockup3_fields.jpg";
import mockup1_0_inspection_settings from "../../../../src/blog/new-assets/inspection2/mockup1_0_inspection_settings.png";
import mockup1_1_inspection_settings from "../../../../src/blog/new-assets/inspection2/mockup1_1_inspection_settings.png";
import img_process_planning from "../../../../src/blog/new-assets/inspection2/img_process_planning.jpg";
import * as React from 'react';
export default {
  img0_inspection_form,
  img1_create_form,
  img2_name_type_instru,
  img3_question_settings,
  img4_question_groups,
  img5_follow_up_action,
  img6_conditional_question,
  mockup2_save,
  mockup3_fields,
  mockup1_0_inspection_settings,
  mockup1_1_inspection_settings,
  img_process_planning,
  React
};