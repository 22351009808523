import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({
  title,
  description,
  _data,
  title2 = true,
  descriptionog = "",
  titleog = "",
  image = "",
  url = "",
}) => {
  let data = {}
  try {
    const result = useStaticQuery(graphql`
      {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `)
    data = result.site.siteMetadata
  } catch (error) {
    data = _data
  }

  const isBrowser = typeof window !== "undefined"
  const Metadescription = description || data?.description
  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={title2 ? `${title} | ${data?.title}` : title}
      meta={[
        { name: `description`, content: Metadescription },
        { name: `robots`, content: "index, follow" },
        { name: `rights`, content: "© Copyright MAXapps maximo mobile. 2022" },
        { property: `og:title`, content: titleog },
        {
          property: `og:description`,
          content: descriptionog || "maximo mobile",
        },
        { property: `og:image`, content: image },
        { property: `og:url`, content: url },
        { name: `twitter:card`, content: "summary" },
        { name: `twitter:site`, content: "@maxapps" },
        { name: `twitter:title`, content: titleog },
        { name: `twitter:description`, content: descriptionog },
        { name: `twitter:image`, content: image },
      ]}
    >
      {isBrowser && (
        <script>
          {(function (h, o, t, j, a, r) {
            h.hj =
              h.hj ||
              function () {
                ;(h.hj.q = h.hj.q || []).push(arguments)
              }
            h._hjSettings = { hjid: 2653395, hjsv: 6 }
            a = o.getElementsByTagName("head")[0]
            r = o.createElement("script")
            r.async = 1
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
            a.appendChild(r)
          })(
            window,
            document,
            "https://static.hotjar.com/c/hotjar-",
            ".js?sv="
          )}
        </script>
      )}
    </Helmet>
  )
}

export default Seo
