import { Link } from "gatsby"
import React from "react"
import "./index.scss"
const LineNavBates = () => {
  const data = [
    {
      title: "The Challenge"
    },
    {
      title: "The context"
    },
    {
      title: "The solution"  
    },
  ]
  
  return (
    <div id="LineNav" className="LineNav d-flex px-md-5 " data-testid="LineNav">
      <div className="row container p-0">
        {data.map((elt, index) => {
          return (
            <Link className="col-lg-3 col-10 item " to={`#${elt.title.split(' ').join('').toLowerCase()}`}  key={index}>
              <p className="num p-0">0{index + 1}</p>
              <p className="title p-0">{elt.title}</p>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default LineNavBates
