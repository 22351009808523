import React from "react"
import "./index.scss"
import { Link } from "gatsby"

const NextButtton = ({ href, title }) => {
  return (
    <div id="next-button" className="next-button pt-5 text-end">
      <Link className="link" to={href}>
      {title}
        <i className="bi bi-arrow-right-short pt-1 px-2"></i>
      </Link>
    </div>
  )
}

export default NextButtton
