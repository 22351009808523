import React from "react"
import "./index.scss"
const TitleDocumentation = ({ _result }) => {
  let result = {}
  try {
    const TitleDoc = {
      
      titleCol: "How-to Guides",
      subTitle1:"Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
    }
    result = TitleDoc
  } catch {
    result = _result
  }
  return (
    <div
      id="TitleDocumentation"
      className="TitleDocumentation py-2 "
      data-testid="TitleDocumentation"
    >
    
      <h1 className="title">
       <span className="titleColored">{result.titleCol}</span>
      </h1>
      <p className="para py-2">{result.subTitle1}</p>
    </div>
  )
}

export default TitleDocumentation
