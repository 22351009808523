import React from "react"
import "./index.scss"

const BlockQuote = ({ person,children }) => {
  return (
    <div id="BlockQuote" className="sect py-5 blockquote row" data-testid="BlockQuote">
      <div className="d-flex  px-md-5 p-0 ">
        <p className="par">{children}</p>
      </div>
      <p className="d-flex px-5 my-2 person ">{person}</p>
    </div>
  )
}

export default BlockQuote
