import React from "react"
import "./index.scss"
const TitleDocumentation = ({ _result }) => {
  let result = {}
  try {
    const TitleDoc = {
      titleCol: "Components",
      subTitle1:
        "Componenets are building blocks for your MAXapps application. You use components to build your target process, a different user experience... Components are divided into two categories, as shown below:",
    }
    result = TitleDoc
  } catch {
    result = _result
  }
  return (
    <div
      id="TitleDocumentation"
      className="TitleDocumentation py-2 "
      data-testid="TitleDocumentation"
    >
      <h1 className="title">
        <span className="titleColored">{result.titleCol}</span>
      </h1>
      <p className="para py-2">{result.subTitle1}</p>
    </div>
  )
}

export default TitleDocumentation
