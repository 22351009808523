import switch_map_view from "../../../../src/assets/animations/map/1- switch to map view.gif";
import pin_detail from "../../../../src/assets/animations/map/2- pin detail.gif";
import pullup from "../../../../src/assets/animations/map/3- pullup.gif";
import swipe_action from "../../../../src/assets/animations/map/4- swipe action.gif";
import maps_experience from "../../../../src/assets/animations/map/5- maps-experience.gif";
import * as React from 'react';
export default {
  switch_map_view,
  pin_detail,
  pullup,
  swipe_action,
  maps_experience,
  React
};