import React, { useState } from "react"
import { Lightbox } from "react-modal-image"
import "./index.scss"

const ImageDocs = ({ src, alt, type, fullSizeSrc }) => {
  const [showModal, setShowModal] = useState(false)

  let stylesImg = ""

  switch (type) {
    case "square":
      stylesImg = "img-fluid col-12 col-md-6 "
      break
    case "portrait":
      stylesImg = "img-fluid col-10 col-md-4  "
      break

    default:
      stylesImg = "img-fluid"
      break
  }

  return (
    <div
      id="ImageDocs"
      className="ImageDocs d-flex justify-content-center py-2 img-hover-zoom"
      data-testid="ImageDocs"
    >
      <img
        src={src}
        alt={alt}
        className={`${stylesImg}`}
        onClick={() => {
          setShowModal(true)
        }}
      />
      {showModal && (
        <Lightbox
          medium={src}
          large={fullSizeSrc || src}
          alt={alt}
          onClose={() => {
            setShowModal(false)
          }}
        />
      )}
    </div>
  )
}

export default ImageDocs
