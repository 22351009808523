import adSearchOrderBy from "../../../../src/assets/images/adSearchOrderBy.png";
import orderByTypeDescLocation from "../../../../src/assets/images/orderByTypeDescLocation.png";
import wClause from "../../../../src/assets/animations/wClause1.gif";
import wClauses from "../../../../src/assets/animations/wClause2.gif";
import * as React from 'react';
export default {
  adSearchOrderBy,
  orderByTypeDescLocation,
  wClause,
  wClauses,
  React
};