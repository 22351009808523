import React from "react"
import TitleDocumentation from "./title"
import DocumentationCards from "./documentation-cards"
import ArticleSection from "./article-section"
import DocBottomSection from "./doc-bottom-section"
const Documentation = () => {
  return (
    <div className="px-4 px-sm-0 mx-3">
      <TitleDocumentation />
      <DocumentationCards />
      <ArticleSection />
      <DocBottomSection />
    </div>
  )
}

export default Documentation
