import React from "react"
import "./index.scss"
const TitleDocumentation = ({ _result }) => {
  let result = {}
  try {
    const TitleDoc = {
      title: "Welcome to the",
      titleCol: "MAXapps Way of Building",
      subTitle1:
        "MAXapps is a Maximo mobile solution that enables you to build applications in compliance with your processes. It is an IBM Maximo mobile solution that increases the engagement of your users and gathers reliable, real-time information. MAXapps' mission is to make mobilizing your business a simple task!",
    }
    result = TitleDoc
  } catch {
    result = _result
  }
  return (
    <div
      id="TitleDocumentation"
      className="TitleDocumentation py-2 "
      data-testid="TitleDocumentation"
    >
      <h1 className="title">
        {result.title} <span className="titleColored">{result.titleCol}</span>
      </h1>
      <p className="para py-2">{result.subTitle1}</p>
    </div>
  )
}

export default TitleDocumentation
