import calendar_view from "../../../../src/assets/animations/calendar-view.gif";
import week_view from "../../../../src/assets/animations/week_view.gif";
import detail_view from "../../../../src/assets/animations/detail_view.gif";
import swipe_view from "../../../../src/assets/animations/swipe_view.gif";
import calendarpng from "../../../../src/assets/images/calendar.jpg";
import ImageDetails from "../../../../src/components/image-details";
import * as React from 'react';
export default {
  calendar_view,
  week_view,
  detail_view,
  swipe_view,
  calendarpng,
  ImageDetails,
  React
};