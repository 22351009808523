import maxapps_designer from "../../../../src/assets/images/docs/how-to/build/designer-application/maxapps-designer.png";
import add_gif from "../../../../src/assets/images/docs/add.gif";
import list_childs from "../../../../src/assets/images/docs/how-to/build/designer-application/list-childs.png";
import designer_hierarchy from "../../../../src/assets/images/docs/how-to/build/designer-application/designer-hierarchy.png";
import empty_page_1 from "../../../../src/assets/images/docs/how-to/build/designer-application/empty_page_1.png";
import single_app_2 from "../../../../src/assets/images/docs/how-to/build/designer-application/single_app_2.png";
import attributes_5 from "../../../../src/assets/images/docs/how-to/build/designer-application/attributes_5.png";
import whereclause_3 from "../../../../src/assets/images/docs/how-to/build/designer-application/whereclause_3.gif";
import login_4 from "../../../../src/assets/images/docs/how-to/build/designer-application/login_4.gif";
import * as React from 'react';
export default {
  maxapps_designer,
  add_gif,
  list_childs,
  designer_hierarchy,
  empty_page_1,
  single_app_2,
  attributes_5,
  whereclause_3,
  login_4,
  React
};