import React from "react"
import { Link } from "gatsby"
import { BsArrowRight } from "react-icons/bs"
import "./index.scss"
import { getSubItemsCount } from "../../../../templates/util"
const ArticleItem = ({ data, metaData }) => {
  const { text, subItems } = data
  let details = metaData.filter(
    inf => inf.title.toLowerCase() === text.toLowerCase()
  )
  let hasOverview = subItems.find(s => s.text.toLowerCase() === "overview")

  let subItemCount = getSubItemsCount(subItems)

  return (
    <div id="ArticleItem" className="ArticleItem " data-testid="ArticleItem">
      <div className="titleSection">
        {subItems.length > 0 && (
          <Link to={subItems[0]?.link} className="link">
            <div className="row imgDoc">
              <h2 className="d-flex flex-row title col-12 col-sm-9 col-md-6">
                <img alt="" src={details[0]?.icon} className="pt-1" />
                &nbsp;
                <span className="titleStyle ">
                  <span className="coloredTitle"> {text.toUpperCase()}</span>
                </span>
                <span className="arrow">
                  <BsArrowRight size={20} />
                </span>
              </h2>
              {
                <div className="badge col-9 col-sm-2  ">
                  <img alt="" src="/article.svg" className="imageBadge" />
                  &nbsp;
                  <div className="article">
                    {hasOverview ? subItemCount - 1 : subItemCount} Articles
                  </div>
                </div>
              }
            </div>
          </Link>
        )}
      </div>
      {subItems.length > 0 && (
        <div className="paraSection row">
          <p className="para col-12 col-md-6 py-2">
            {details[0]?.para1}
            <br />
            {details[0]?.para2}
          </p>
          <ul className=" list col-12 col-md-6 py-2">
            {subItems.map((item, index) => {
              return (
                index !== 0 &&
                index < 3 && (
                  <li className="linkSide" key={index}>
                    <img alt="" src="/listIcon.png" className="imageList" />
                    &nbsp;
                    <Link className="LinkStyle" to={item.link}>
                      {item.text}
                    </Link>
                  </li>
                )
              )
            })}
            <Link className="linkDoc" to={subItems[0]?.link}>
              <img alt="" src="/arrow.svg" className="imgDoc" />
              &nbsp;&nbsp;
              <span className="text">
                see all {hasOverview ? subItemCount - 1 : subItemCount}
              </span>
            </Link>
          </ul>
        </div>
      )}
    </div>
  )
}

export default ArticleItem
