import image_media from "../../../../src/assets/animations/image_media.gif";
import media from "../../../../src/assets/animations/media.gif";
import preview from "../../../../src/assets/images/docs/how-to/features/multimedia/preview.gif";
import rearrange_image from "../../../../src/assets/images/docs/how-to/features/multimedia/rearrange_image.gif";
import delete_image from "../../../../src/assets/images/docs/how-to/features/multimedia/delete_image.gif";
import * as React from 'react';
export default {
  image_media,
  media,
  preview,
  rearrange_image,
  delete_image,
  React
};