import designer_error_xml from "../../../../src/assets/images/docs/how-to/build/designer-file/error-xml.png";
import sr_widget_edited from "../../../../src/assets/images/docs/how-to/build/designer-file/sr_widget_edited.gif";
import export_gif from "../../../../src/assets/images/docs/export.gif";
import import_publish_gif from "../../../../src/assets/images/docs/import_publish.gif";
import * as React from 'react';
export default {
  designer_error_xml,
  sr_widget_edited,
  export_gif,
  import_publish_gif,
  React
};