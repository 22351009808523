import React from "react"
import { Link } from "gatsby"
import { BsArrowRight } from "react-icons/bs"
import "./index.scss"
const DocBottomSection = ({ _data }) => {
  let result = {}
  try {
    const FooterSectionDoc = {
      icon: "/consoleDoc.png",
      link: "MAXapps Console ",
      title:
        "Looking to start using MAXApps? Take advantage of the two free accounts and head over to the ",
    }
    result = FooterSectionDoc
  } catch {
    result = _data
  }
  return (
    <div
      id="DocBottomSection"
      className="DocBottomSection d-flex flex-row"
      data-testid="DocBottomSection"
    >
      <div>
        <img
          alt=""
          src={result.icon}
          data-testid="Icon"
          className="imgConsole px-1"
        />
      </div>
      <div>
        {result.title}
        <Link to="/console/signup/" className=" linkSection ">
          {result.link} <BsArrowRight size={20} className="doc-bottom-arrow" />
        </Link>
        .
      </div>
    </div>
  )
}

export default DocBottomSection
