import timer from "../../../../src/assets/images/timer.png";
import active_timer2 from "../../../../src/assets/images/widgets/stop_go/active_timer2.jpeg";
import workorder from "../../../../src/assets/images/workorder.png";
import error_status from "../../../../src/assets/images/docs/how-to/functionalities/stop-and-go/error_status.gif";
import stop_go from "../../../../src/assets/animations/widgets/stop_go.gif";
import * as React from 'react';
export default {
  timer,
  active_timer2,
  workorder,
  error_status,
  stop_go,
  React
};