import React from "react"
import { Link } from "gatsby"
import "./index.scss"

const CardItem = props => {
  const { title = "test", subTitle = "", titleButton = "", goTo = "" } = props

  return (
    <div id="CardItem" className="CardItem" data-testid="CardItem">
      <div className="titleContainer">
        <Link className="link" to={goTo}>
          <h2 className="title m-0 px-2 pb-2">{title}</h2>
          <div className="  py-1 px-2">
            <p className="description p-0 m-0">{subTitle}</p>
          </div>

          <div className="px-2 pt-5 mt-2">
            <div className="btnContainer py-1  ">
              <button
                type="button"
                className="btnDoc px-1 py-1"
                data-testid="Button"
              >
                {titleButton}
              </button>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default CardItem
