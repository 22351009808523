import React from "react"
import ArticleItem from "./article-item"
import { useStaticQuery, graphql } from "gatsby"
import { getSidebarEntries } from "../../../../templates/util"
const ArticleSection = ({ slug }) => {
  const data = useStaticQuery(graphql`
    {
      allMdx {
        nodes {
          slug
          fields {
            source
          }
          frontmatter {
            title
            order
          }
        }
      }
    }
  `)
  const metaData = [
    {
      para1:
        "Learn the basic functions that you will use constantly while writing your MAXapps application.",
      title: "features",
      para2: "",
      icon: "/electric-plugin.png",
    },
    {
      para1:
        "Learn the different ways to edit, build, stage and distribute your MAXapps application.",
      title: "build",
      para2: "",
      icon: "/manual.png",
    },
    {
      para1:
        "Discover the functionalities that will make your MAXapps application feels alive and interactive.",
      title: "functionalities",
      para2: "",
      icon: "/value-chain.png",
    },
    {
      para1:
        "Discover some advanced features that will add significant value to your MAXapps application and user experience.",
      title: "advanced-features",
      para2: "",
      icon: "/files.png",
    },
  ]
  const info = data.allMdx.nodes
  let defaultSideBarEntries = getSidebarEntries(
    info.filter(node => node.fields.source === "docs")
  )
  const slugParts = slug.split("/")
  const parent = slugParts[1]
  let currentEntries =
    slugParts.length === 1
      ? defaultSideBarEntries
      : defaultSideBarEntries.filter(el => {
          return el.text === parent
        })
  return (
    <div id="ArticleSection">
      {currentEntries[0].subItems.map((item, index) => {
        return (
          index > 0 && (
            <div className="py-2" key={index}>
              <ArticleItem data={item} metaData={metaData} />
            </div>
          )
        )
      })}
    </div>
  )
}

export default ArticleSection
