import React from "react"
import "./index.scss"
const CardUseCase = ({ children, title, image }) => {
  return (
    <div id="card" className="cardUseCase d-flex " data-testid="card">
      <div className="usecase-card">
        <img
          src={image}
          title={`Maxapps: ${title}`}
          alt={`Maxapps: ${title}`}
          className="mb-2 image"
        />
        <h3 className="text-md-center text-lg-start">{title}</h3>
        <p className="par">{children}</p>
      </div>
    </div>
  )
}

export default CardUseCase
