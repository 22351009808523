import React from "react"
import ArticleItem from "./article-item"
import { useStaticQuery, graphql } from "gatsby"
import { getSidebarEntries } from "../../../templates/util"
const ArticleSection = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx {
        nodes {
          slug
          fields {
            source
          }
          frontmatter {
            title
            order
          }
        }
      }
    }
  `)
  const metaData = [
    {
      icon: "/value-chain.png",
      title: "Get started",
      para1: "Step-by-step guides to help you get started. ",
      para2: "Most useful when you're new to MAXapps.",
    },
    {
      icon: "/files.png",
      para1:
        "Practical step-by-step guides to help you achieve a specific goal. Most",
      para2: " useful when you're trying to get something done.",
      title: "how-to",
    },
    {
      icon: "/manual.png",
      para1:
        "Nitty-gritty technical description of how MAXapps works. Most useful",
      title: "Reference",
      para2:
        "when you need detailed information about MAXapps' configuration and components.",
    },
    {
      icon: "/electric-plugin.png",
      title: "Component",
      para1:
        "Detailed description of the components and their properties. Usefull to get the most from each component.",
      para2: "",
    },
  ]
  const info = data.allMdx.nodes
  let structeredNodes = getSidebarEntries(
    info.filter(node => node.fields.source === "docs")
  )

  return (
    <div id="ArticleSection">
      {structeredNodes.map((item, index) => {
        return (
          index > 0 && (
            <div className="py-2" key={index}>
              <ArticleItem data={item} metaData={metaData} />
            </div>
          )
        )
      })}
    </div>
  )
}

export default ArticleSection
