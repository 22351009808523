import UsesCaseTitle from "../../../../src/components/use-cases/use-cases-detail/title";
import LineNavG4S from "../../../../src/components/use-cases/use-cases-detail/sections/line-nav/line-nav-g4s";
import BlockQuote from "../../../../src/components/use-cases/use-cases-detail/blockquote";
import Section from "../../../../src/components/use-cases/use-cases-detail/sections";
import CardUseCase from "../../../../src/components/use-cases/use-cases-detail/sections/card";
import Seo from "../../../../src/components/seo";
import { Helmet } from "react-helmet";
import * as React from 'react';
export default {
  UsesCaseTitle,
  LineNavG4S,
  BlockQuote,
  Section,
  CardUseCase,
  Seo,
  Helmet,
  React
};